import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Indonesia",
  "subtitle": "September 2018",
  "category": "Asia"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.grab.com/id/"
        }}>{`GRAB`}</a>{` - Basically Uber`}</li>
      <li parentName="ul">{`Local SIM - I picked one up at GraPARI roaming on Telkomsel; The internet is censored!`}</li>
    </ul>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Kerak_telor"
        }}>{`Kerak telor`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Kue_pancong"
        }}>{`Kue pancong`}</a>{` - Coconut cake sold on street carts`}</li>
    </ul>
    <h2 {...{
      "id": "jakarta"
    }}>{`Jakarta`}</h2>
    <ul>
      <li parentName="ul">{`Not my kind of city`}</li>
    </ul>
    <h3 {...{
      "id": "food-1"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/4DH7Nsfzv3zcdccLA"
        }}>{`Njonja Besar Ice Popsicles`}</a>{` - Kacang ijo flavor (?)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/ViGzGBtHLpqx1QMf6"
        }}>{`Glodok Chinatown Market`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/AE8paz5nPXnECqGc6"
        }}>{`Warteg Gang Mangga`}</a></li>
    </ul>
    <h4 {...{
      "id": "taman-fatahillah"
    }}>{`Taman Fatahillah`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/jwERrUKJuG2nV9He7"
        }}>{`Acaraki`}</a>{` - There are other locations but this one is in an alley. It's like a hipster take on traditional Jamu drink. I thought the "Saranti" was good!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/UdNcubML3HZf7wH78"
        }}>{`Cafe Batavia`}</a>{` - Long waits; not really sure it's worth the hype`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/iT4mThppWgbF68fU9"
        }}>{`National Monument`}</a>{` - I only took a photo. It did not look interesting at all.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/4FChxVmtUU2uNiK48"
        }}>{`Istiqlal Mosque`}</a>{` - No shoes indoors and areas are gender-separated. About ~15 min walk from the National Monument`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/fmsy9vXuhd2RS9LN9"
        }}>{`Museum Bank Indonesia`}</a>{` - There's a room with a HUGE Gold bar`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/qeEKhSJS3PEkYhPY9"
        }}>{`Museum MACAN`}</a>{` - Modern art museum that was under construction when I went...`}</li>
    </ul>
    <h4 {...{
      "id": "taman-fatahillah-1"
    }}>{`Taman Fatahillah`}</h4>
    <ul>
      <li parentName="ul">{`I was underwhelmed by this whole area but there's a lot to see`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/fkymnHt63mLpTpbw6"
        }}>{`Museum of Fine Art and Ceramics`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/mVkiE5yiViL9uQm77"
        }}>{`Jakarta History Museum`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/9Q6udcLPKXyX5jh9A"
        }}>{`Wayang Museum`}</a>{` - Javanese puppetry museum`}</li>
    </ul>
    <h2 {...{
      "id": "yogyakarta"
    }}>{`Yogyakarta`}</h2>
    <ul>
      <li parentName="ul">{`This town was full of ex-pats`}</li>
      <li parentName="ul">{`Many handicrafts and leather stores which also offered workshops`}</li>
    </ul>
    <h3 {...{
      "id": "food-2"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/CDSMihaNK1P4G3p19"
        }}>{`Mediterranea Restaurant by Kamil`}</a>{` - Had amazing food here and there was also an AC room`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "hhttps://maps.app.goo.gl/wnKiyM5W2CQAbtAA8"
        }}>{`Nanamia Pizzeria`}</a>{` - Outdoor seating had a garden; Did not notice an AC room`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/DbY1MNY95WWN7uQr9"
        }}>{`Chocolate Monggo Tirtodipuran`}</a>{` - Great chocolate in a very hot country`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/VkN37uEvfnd4Dsuo6"
        }}>{`ViaVia`}</a>{` - Fair trade shop that supports the local community but also a restaurant`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/r8CFPoiVNUhFaiMn9"
        }}>{`Langgeng Art Foundation`}</a>{` - Small exhibit with a coffee shop`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-1"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/VKop4mbQ9FM34UCJ8"
        }}>{`Borobudur temple`}</a>{` and `}<a parentName="li" {...{
          "href": "https://maps.app.goo.gl/QeWduBp8ktjNhWP36"
        }}>{`Prambanan temple`}</a>
        <ul parentName="li">
          <li parentName="ul">{`I went with a guided tour`}</li>
          <li parentName="ul">{`Sunset view at Prambanan was included`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/EfD7nUtYEuNm6b1H8"
        }}>{`Ramayana Ballet Prambanan`}</a>
        <ul parentName="li">
          <li parentName="ul">{`I enjoyed the `}<a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/Ramayana_Ballet"
            }}>{`ballet`}</a>{`; 1st row special seats were great for me. VIP seats were more centered but I liked the view of the temple where I was sitting. Both were good options.`}</li>
          <li parentName="ul">{`Show runs a bit late (I got back around ~10:30pm?) and the performers also held a meet and greet after.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "bali"
    }}>{`Bali`}</h2>
    <h2 {...{
      "id": "nusa-lembongan"
    }}>{`Nusa Lembongan`}</h2>
    <ul>
      <li parentName="ul">{`I took a boat from Bali with with `}<a parentName="li" {...{
          "href": "https://rockyfastcruise.com/"
        }}>{`Rocky cruise`}</a>{`; Be sure to wear flip flops and shorts cause the boat boards on the shore water and it was knee deep!`}</li>
      <li parentName="ul">{`Bring cash! People said they had issues with the ATM having no money or got scammed by it.`}</li>
    </ul>
    <h3 {...{
      "id": "food-3"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/npsPV8ijVexuAuJu5"
        }}>{`Lemongrass`}</a>{` - Rsvp in advance advised`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/A4AQvns7V6xEf3yq6/"
        }}>{`Bali Eco Deli`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/gZG8uPbyLphWxz1SA"
        }}>{`Ginger & Jamu`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/3KXDDNMQfhRrv6YV6"
        }}>{`Pondok Baruna Warung`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/j9MsEUzisH9LP1e69"
        }}>{`Tiki cafe and bar`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/MbTbuxC681RJaCM39"
        }}>{`Canteen Kitchen + Bar`}</a>{` - Next to `}<a parentName="li" {...{
          "href": "https://maps.app.goo.gl/wjYx53oPjWStaTML6"
        }}>{`Blue Corner Dive`}</a></li>
    </ul>
    <h3 {...{
      "id": "things-to-do-2"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/wjYx53oPjWStaTML6"
        }}>{`Blue Corner Dive`}</a>{` is where I got my Advanced Open Waters (5 dives)`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.padi.com/dive-site/indonesia/manta-point-3/"
            }}>{`Manta Point`}</a>{` - deep dive`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.padi.com/dive-site/indonesia/crystal-bay-2/"
            }}>{`Crystal Point`}</a>{` - digital underwater imaging`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.padi.com/dive-site/indonesia/mangrove/"
            }}>{`Mangrove point`}</a>{` - drift dive`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.padi.com/dive-site/indonesia/pmg-pura-mas-gading/"
            }}>{`Pura Mas Gading`}</a>{` - fish identification`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.padi.com/dive-site/indonesia/sd-point/"
            }}>{`Sekolah Dasar`}</a>{` - underwater navigation`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Snorkeling at Manta bay, Crystal bay, and Mangrove point - Recommend only if you're won't be scuba diving`}</li>
      <li parentName="ul">{`Surfing lessons - I didn't have time but there were lots of shops`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/4FaGvsU2e94L2PfP7"
        }}>{`Yoga Shack`}</a>{` - Cute yoga spot`}</li>
    </ul>
    <h2 {...{
      "id": "seminyak"
    }}>{`Seminyak`}</h2>
    <h3 {...{
      "id": "food-4"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/AFCSMxkjhLytbxrF9"
        }}>{`Desa Potato Head`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/UBmrm9mLf1zcjhsw9"
            }}>{`Ijen`}</a>{` - Great food! Water bottles not allowed`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/z2JPTUDL3ZQiKAeh9"
        }}>{`KU DE TA`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/UnhBM5F7aY7HDdrN7"
            }}>{`Mejekawi`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/u8oTiP2rZYepUDD16"
        }}>{`Mad pops`}</a></li>
    </ul>
    <h3 {...{
      "id": "things-to-do-3"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/JFWqs7nXYwFaXwy57"
        }}>{`Seminyak Square`}</a>{` - Lots of shopping`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      